import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs';
import { FaFacebookF, FaGithub } from 'react-icons/fa';

const SocialMedia = () => {
	const handleSocials = (link) => {
		//open a new tab
		window.open(link, '_blank');
	};
	return (
		<div className='app__social'>
			<div
				onClick={() =>
					handleSocials('https://www.linkedin.com/in/meljason/')
				}
				style={{ cursor: 'pointer' }}
			>
				<BsLinkedin />
			</div>
			<div
				onClick={() =>
					handleSocials('https://www.github.com/meljason/')
				}
				style={{ cursor: 'pointer' }}
			>
				<FaGithub />
			</div>
			{/* <div>
				<BsInstagram />
			</div> */}
		</div>
	);
};

export default SocialMedia;
