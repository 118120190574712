import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';

import './Testimonial.scss';
const Testimonial = () => {
	const [brands, setBrands] = useState([]);
	const [testimonials, setTestimonials] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [timeoutId, setTimeoutId] = useState(null);

	useEffect(() => {
		const query = '*[_type == "testimonials"]';
		const brandsQuery = '*[_type == "brands"]';

		client.fetch(query).then((data) => {
			setTestimonials(data);
		});
		client.fetch(brandsQuery).then((data) => {
			setBrands(data);
		});
	}, []);

	const test = testimonials[currentIndex];

	const handleClick = (index) => {
		setCurrentIndex(index);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			setCurrentIndex((currentIndex + 1) % testimonials.length);
		}, 5000);

		setTimeoutId(timeout);
		return () => clearTimeout(timeout);
	}, [currentIndex, testimonials.length]);

	const handleHover = (isHovering) => {
		if (isHovering) {
			clearTimeout(timeoutId);
		} else {
			const timeout = setTimeout(() => {
				setCurrentIndex((currentIndex + 1) % testimonials.length);
			}, 5000);
			setTimeoutId(timeout);
		}
	};

	return (
		<>
			{testimonials?.length && (
				<>
					<motion.div
						className='app__testimonial-item app__flex'
						onMouseEnter={() => handleHover(true)}
						onMouseLeave={() => handleHover(false)}
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -20 }}
						transition={{ duration: 0.5, ease: 'easeInOut' }}
					>
						<img
							src={urlFor(test.imgurl)}
							alt={testimonials[currentIndex].name}
						/>
						<div className='app__testimonial-content'>
							<p className='p-text'>{test.feedback}</p>
							<div>
								<h4 className='bold-text'>{test.name}</h4>
								<h5 className='p-text'>{test.company}</h5>
							</div>
						</div>
					</motion.div>
					<div className='app__testimonial-btns app__flex'>
						<div
							className='app__flex'
							onClick={() =>
								handleClick(
									currentIndex === 0
										? testimonials.length - 1
										: currentIndex - 1
								)
							}
						>
							<HiChevronLeft />
						</div>

						<div
							className='app__flex'
							onClick={() =>
								handleClick(
									currentIndex === testimonials.length - 1
										? 0
										: currentIndex + 1
								)
							}
						>
							<HiChevronRight />
						</div>
					</div>
				</>
			)}

			<div className='app__testimonial-brands app__flex'>
				{brands.map((brand) => (
					<motion.div
						whileInView={{ opacity: [0, 1] }}
						transition={{ duration: 0.5, type: 'tween' }}
						key={brand._id}
					>
						<img src={urlFor(brand.imgUrl)} alt={brand.name} />
					</motion.div>
				))}
			</div>
		</>
	);
};

export default AppWrap(
	MotionWrap(Testimonial, 'app__testimonial'),
	'testimonial',
	'app__primarybg'
);
