import React, { useState, useEffect } from 'react';
import './Footer.scss';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';

const Footer = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const { name, email, message } = formData;

	const handleChangeInput = (e) => {
		const { name, value } = e.target;

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = () => {
		setLoading(true);

		const contact = {
			_type: 'contact',
			name: name,
			email: email,
			message: message,
		};

		client.create(contact).then(() => {
			setLoading(false);
			setFormSubmitted(true);
		});
	};

	return (
		<>
			<h2 className='head-text'>Take a coffee & chat with me</h2>
			<div className='app__footer-cards'>
				<div className='app__footer-card'>
					<img src={images.email} alt='email' />
					<a href='mailto:contact@jasonchong.tech' className='p-text'>
						contact@jasonchong.tech
					</a>
				</div>
				<div className='app__footer-card'>
					<img src={images.mobile} alt='mobile' />
					<a href='tel: +1 (647) 519-7134' className='p-text'>
						+1 (647) 519-7134
					</a>
				</div>
			</div>

			{!formSubmitted ? (
				<div className='app__footer-form app__flex'>
					<div className='app__flex'>
						<input
							className='p-text'
							text='text'
							placeholder='Your Name'
							value={name}
							name={'name'}
							onChange={handleChangeInput}
						/>
					</div>
					<div className='app__flex'>
						<input
							className='p-text'
							text='text'
							placeholder='Your Email'
							value={email}
							name={'email'}
							onChange={handleChangeInput}
						/>
					</div>
					<div>
						<textarea
							className='p-text'
							placeholder='Your Message'
							value={message}
							name={'message'}
							onChange={handleChangeInput}
						/>
					</div>

					<button
						type='button'
						className='p-text'
						onClick={handleSubmit}
					>
						{loading ? 'Sending...' : 'Send Message'}
					</button>
				</div>
			) : (
				<div>
					<h3 className='head-text'>
						Thank you for getting in touch!
					</h3>
				</div>
			)}
		</>
	);
};

export default AppWrap(
	MotionWrap(Footer, 'app__footer'),
	'contact',
	'app__whitebg'
);
